<template>
  <div>
    <v-textarea
        :disabled="readOnly"
        rows="3"
        auto-grow
        v-model="instructionsJoined"
        label="Customer Prep Instructions."
    />
    <v-expand-transition>
      <v-toolbar flat dense v-if="changed">
        <v-btn color="primary" class="mr-4" @click="save">Save</v-btn>
        <v-btn @click="revert">Cancel</v-btn>
      </v-toolbar>
    </v-expand-transition>

    <v-snackbar
        v-model="saved"
        timeout="1000"
    >
      Updated instructions
    </v-snackbar>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';

export default {
  name: "InstructionEditor",
  props: {
    readOnly: Boolean,
    meal: Object
  },
  mounted() {
    this.instructionsJoined = this.meal.instructions.join('\n');
  },
  data() {
    return {
      instructionsJoined: null,
      saved: false,
    }
  },
  methods: {
    ...mapActions(['updateMeal']),
    save() {
      this.meal.instructions = this.instructionsJoined.split('\n');
      this.updateMeal({mealId: this.meal.id, update: {instructions: this.meal.instructions}})
          .then(() => {
            this.saved = true;
            this.instructionsJoined = this.meal.instructions.join('\n');
          })

    },
    revert() {
      this.instructionsJoined = this.meal.instructions.join('\n');
    }
  },
  computed: {
    ...mapGetters(['getMeal']),
    changed() {
      return this.instructionsJoined !== null &&
          this.instructionsJoined !== this.meal.instructions.join('\n');
    }
  }
}
</script>

<style scoped>

</style>
